import React from "react";
import ReactDOM from "react-dom";

import "./styles.css";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keysPressed: []
    };

    document.addEventListener("keydown", e => {
      //console.log(e.key);
      e.preventDefault();
      e.stopPropagation();
      if (e.repeat) {
        return;
      }
      this.setState({
        keysPressed: [...this.state.keysPressed, e.key.toUpperCase()]
      });
      //console.log(this.state.keysPressed);
      return false;
    });

    document.addEventListener("keypress", e => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    });

    document.addEventListener("keyup", e => {
      this.setState({
        keysPressed: this.state.keysPressed.filter(function(key) {
          return key !== e.key.toUpperCase();
        })
      });
    });
  }

  render() {
    return (
      <div className="App">
        <div className="bigCenter">
          {this.state.keysPressed.map(function(key, index) {
            // Note, do not use index as a key
            return (
              <span key={index}>
                <span key={key}>{key}</span>
                <span>&nbsp;</span>
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
